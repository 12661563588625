import React, { memo } from "react";
import InputTitle from "../../../common/inputTitle";
import { IAppState } from "../../../../types/states";
import { ProductOptions } from "../../../../types/designer";

export type PrintOrientationProps = {
  imageBase64File?: string;
  isPortrait: boolean;
  setState: React.Dispatch<React.SetStateAction<IAppState>>;
  canvasRef: React.RefObject<HTMLCanvasElement>;
  imageCanvasDimensions: { width: number; height: number };
  productSelection: ProductOptions;
};

const PrintOrientation = ({
  imageBase64File,
  isPortrait,
  setState,
  canvasRef,
  imageCanvasDimensions,
  productSelection,
}: PrintOrientationProps) => {
  const landscapeEnabled = productSelection !== "resume";
  const handlePrintOrientationChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (!canvasRef.current) {
      return;
    }

    const x = (canvasRef.current.height - imageCanvasDimensions.width) / 2;
    const y = (canvasRef.current.width - imageCanvasDimensions.height) / 2;
    const newIsPortrait = e.target.id === "portrait";

    setState((prevState) => ({
      ...prevState,
      textEditorValue: "",

      textEditorPosition: {
        x: 45,
        y: newIsPortrait ? 620 : 480,
      },

      imagePositionForCanvas: { x: x, y: y },
      isPortrait: newIsPortrait,
    }));
  };

  return (
    <>
      <div>
        <InputTitle title={"Orientation"} />
        <div className="flex items-start gap-6">
          {/* Portrait mode */}
          <div>
            <input
              type="radio"
              name="print-orientation"
              id="portrait"
              disabled={!imageBase64File}
              checked={isPortrait}
              onChange={handlePrintOrientationChange}
              className="peer hidden"
            />
            <label
              htmlFor="portrait"
              className="peer-checked:[&>span]:border-beige-600 peer-checked:[&>span]:bg-beige-300 peer-checked:[&_p:after]:block peer-disabled:[&>span]:bg-black-200/[.1] peer-disabled:[&>span]:border-black-200/[.1] peer-disabled:[&>span>span]:bg-black-200/[.1] peer-disabled:[&_p:before]:border-black-200/[.3] peer-disabled:[&_p:after]:bg-black-200/[.4] peer-disabled:[&_p]:text-black-200/[.75]"
            >
              <p className="relative text-xs text-black-200 font-normal flex items-center gap-1 mb-1.5 before:block before:w-3 before:h-3 before:border before:border-black-300 before:rounded-full after:absolute after:left-[2px] after:top-1 after:w-2 after:h-2 after:bg-black-300 after:rounded-full after:hidden">
                Portrait
              </p>
              <span className="w-[86px] h-[86px] border border-black-300 rounded flex items-center justify-center peer-checked:border-beige-600">
                <span className="block w-[40px] h-[60px] bg-blue-200"></span>
              </span>
            </label>
          </div>

          {/* landscape mode */}
          {landscapeEnabled && (
            <div>
              <input
                type="radio"
                name="print-orientation"
                id="landscape"
                disabled={!imageBase64File}
                checked={!isPortrait}
                onChange={handlePrintOrientationChange}
                className="peer hidden"
              />
              <label
                htmlFor="landscape"
                className="peer-checked:[&>span]:border-beige-600 peer-checked:[&>span]:bg-beige-300 peer-checked:[&_p:after]:block peer-disabled:[&>span]:bg-black-200/[.1] peer-disabled:[&>span]:border-black-200/[.1] peer-disabled:[&>span>span]:bg-black-200/[.1] peer-disabled:[&_p:before]:border-black-200/[.3] peer-disabled:[&_p:after]:bg-black-200/[.4] peer-disabled:[&_p]:text-black-200/[.75]"
              >
                <p className="relative text-xs text-black-200 font-normal flex items-center gap-1 mb-1.5 before:block before:w-3 before:h-3 before:border before:border-black-300 before:rounded-full after:absolute after:left-[2px] after:top-1 after:w-2 after:h-2 after:bg-black-300 after:rounded-full after:hidden">
                  Landscape
                </p>
                <span className="w-[86px] h-[86px] border border-black-300 rounded flex items-center justify-center">
                  <span className="block w-[60px] h-[40px] bg-blue-200"></span>
                </span>
              </label>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default memo(PrintOrientation);
