import React, { useEffect } from "react";
import { memo } from "react";
import Quill from "react-quill";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { IAppState } from "../../types/states";
import { BorderOptions, BorderTypeOptions } from "../../types/designer";

export type TextEditorProps = {
  textEditorValue: string;
  setState: React.Dispatch<React.SetStateAction<IAppState>>;
  isPortrait: boolean;
  textEditorPosition: { x: number; y: number };
  textEditorWidthPx: number;
  canvasRef: React.RefObject<HTMLCanvasElement>;
  quillRef: React.RefObject<ReactQuill>;
  selectedBorderOption: BorderOptions;
  selectedBorderTypeOption: BorderTypeOptions;
};

const TextEditor = ({
  textEditorValue,
  setState,
  isPortrait,
  textEditorPosition,
  textEditorWidthPx,
  canvasRef,
  quillRef,
  selectedBorderOption,
}: TextEditorProps) => {
  // quillRef = useRef(null);
  const quill = ReactQuill.Quill;
  const font = quill.import("formats/font");
  const size = quill.import("attributors/style/size");
  const textEditorFormats = ["font", "size", "color", "align"];

  // Use 20pt as default font size
  size.whitelist = [];
  size.whitelist.push("20pt");
  for (let i = 10; i <= 20; i++) {
    size.whitelist.push(`${i}pt`);
  }
  for (let i = 22; i <= 40; i += 2) {
    size.whitelist.push(`${i}pt`);
  }
  for (let i = 44; i <= 72; i += 4) {
    size.whitelist.push(`${i}pt`);
  }

  // add different font styles text editor
  font.whitelist = [
    "Serif",
    "Roboto",
    "Arimo",
    "ArimoBold",
    "ArimoItalic",
    "ArimoBoldItalic",
    "CinzelRegular",
    "CinzelBold",
    "NunitoRegular",
    "NunitoBold",
    "NunitoLight",
    "NunitoLightItalic",
    "MerriweatherRegular",
    "MerriweatherBold",
    "MontserratRegular",
    "MontserratBold",
    "ArchivoSemiCondensedRegular",
    "ArchivoSemiCondensedBold",
  ];
  quill.register(font, true);
  quill.register(size, true);

  const textEditorModules = {
    toolbar: [
      [{ font: font.whitelist }],
      [{ size: size.whitelist }],
      [{ color: ["black", "white"] }],
      [{ align: "" }, { align: "center" }, { align: "right" }],
    ],
  };

  const handleTextEditorChange = (value: string) => {
    const canvas = canvasRef.current!;
    const reactQuill = quillRef?.current!;
    if (value === "" || value === "<p><br></p>") {
      const quill = reactQuill.getEditor();
      if (quill && selectedBorderOption !== "noBorder") {
        const color =
          selectedBorderOption === "blackBorder" ? "white" : "black";
        const contentLength = quill.getLength();
        quill.formatText(0, contentLength, "color", color);
        quill.format("color", color);
      }
    }
    if (
      canvas.height -
        textEditorPosition.y -
        reactQuill.getEditor()?.root.offsetHeight -
        45 <
      1
    ) {
      setState((prevState) => ({
        ...prevState,

        textEditorPosition: {
          x: prevState.textEditorPosition.x,
          y:
            canvas.height - reactQuill?.getEditor()?.root.offsetHeight - 45 - 9,
        },
      }));
    }

    setState((prevState) => ({
      ...prevState,
      textEditorValue: value,
    }));
  };

  useEffect(() => {
    const quill = quillRef.current?.getEditor();
    if (quill && selectedBorderOption !== "noBorder") {
      const color = selectedBorderOption === "blackBorder" ? "white" : "black";
      const contentLength = quill.getLength();
      quill.formatText(0, contentLength, "color", color);
      quill.format("color", color);
    }
  }, [quillRef, selectedBorderOption]);

  useEffect(() => {
    const quill = quillRef.current?.getEditor();
    if (quill) {
      quill.root.setAttribute("spellcheck", "false");
      quill.format("size", "20pt");
    }
    const editor = document.querySelector(".ql-editor") as HTMLElement;
    if (editor) {
      editor.style.maxHeight = "470px";
    }
    const elements = document.querySelectorAll('[id^="ql-picker-options-"]');
    const sizeDropdown = elements[0] as HTMLElement;
    const styleDropdown = elements[1] as HTMLElement;
    const el3 = elements[2] as HTMLElement;
    el3.style.width = "max-content";
    sizeDropdown.style.height = "200px";
    sizeDropdown.style.width = "230px";
    sizeDropdown.style.overflow = "auto";
    styleDropdown.style.height = "200px";
    styleDropdown.style.overflow = "auto";

    // eslint-disable-next-line
  }, [quillRef]);

  useEffect(() => {
    const reactQuill = quillRef.current;
    const quillContainer = reactQuill?.editor?.root.parentElement;
    if (quillContainer) {
      quillContainer.style.width = textEditorWidthPx + "px";
    }
  }, [isPortrait, textEditorWidthPx, quillRef]);

  useEffect(() => {
    const canvas = canvasRef.current!;
    const toolbar = document.querySelector(
      ".ql-toolbar.ql-snow",
    ) as HTMLElement;
    const container = document.querySelector(
      ".ql-container.ql-snow",
    ) as HTMLElement;
    const elements = document.querySelectorAll(
      '[id^="ql-picker-options-"]',
    ) as NodeListOf<HTMLElement>;
    const styleDropdown = elements[0];
    const sizeDropdown = elements[1];
    if (toolbar) {
      toolbar.style.width = textEditorWidthPx + "px";
    }
    // toolbar position change on canvas ceiling touch
    if (textEditorPosition.y < 300) {
      container.parentNode?.insertBefore(toolbar, container.nextSibling);
    } else {
      container.parentNode?.insertBefore(toolbar, container);
    }
    if (
      canvas.height -
        textEditorPosition.y -
        (sizeDropdown.clientHeight !== 0 ? sizeDropdown.clientHeight : 230) -
        50 <
      0
    ) {
      sizeDropdown.style.top = "auto";
      sizeDropdown.style.bottom = "100%";
    } else {
      sizeDropdown.style.top = "100%";
      sizeDropdown.style.bottom = "auto";
    }

    if (
      canvas.height -
        textEditorPosition.y -
        (sizeDropdown.clientHeight !== 0 ? sizeDropdown.clientHeight : 230) -
        50 <
      0
    ) {
      styleDropdown.style.top = "auto";
      styleDropdown.style.bottom = "100%";
    } else {
      styleDropdown.style.top = "100%";
      styleDropdown.style.bottom = "auto";
    }
  }, [textEditorPosition, textEditorWidthPx, canvasRef, isPortrait]);

  return (
    <>
      <Quill
        ref={quillRef}
        className="w-fit h-fit group text-white border-none"
        placeholder="Add your name here..."
        modules={textEditorModules}
        formats={textEditorFormats}
        value={textEditorValue}
        onChange={handleTextEditorChange}
        theme="snow"
      />
    </>
  );
};

export default memo(TextEditor);
