import { IAppState } from "../types/states";

const handleTextEditorMouseDown = (
  event: React.MouseEvent,
  setState: React.Dispatch<React.SetStateAction<IAppState>>,
  canvasRef: React.RefObject<HTMLCanvasElement>,
) => {
  const mouseX = event.clientX;
  const mouseY = event.clientY;
  const startDragPos = { x: mouseX, y: mouseY };

  const handleMouseMove = (moveEvent: MouseEvent) => {
    const textEditorRect = document
      .querySelector(".ql-container.ql-snow")
      ?.getBoundingClientRect();

    if (textEditorRect === undefined) {
      return;
    }

    if (!canvasRef.current) {
      return;
    }
    const canvasRect = canvasRef.current.getBoundingClientRect();
    const currentX = moveEvent.clientX;
    const currentY = moveEvent.clientY;
    const dx = currentX - startDragPos.x;
    const dy = currentY - startDragPos.y;

    // Check if the new position stays within canvas boundaries
    const newTextEditorX = textEditorRect.left + dx;
    const newTextEditorY = textEditorRect.top + dy;
    const isWithinCanvas =
      newTextEditorX >= canvasRect.left + 9 &&
      newTextEditorX + textEditorRect.width <= canvasRect.right - 9 &&
      newTextEditorY >= canvasRect.top + 9 &&
      newTextEditorY + textEditorRect.height <= canvasRect.bottom - 9;

    if (isWithinCanvas) {
      setState((prevState) => ({
        ...prevState,

        textEditorPosition: {
          x: prevState.textEditorPosition.x + dx,
          y: prevState.textEditorPosition.y + dy,
        },
      }));
    }
    startDragPos.x = currentX;
    startDragPos.y = currentY;
  };
  const handleMouseUp = () => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  document.addEventListener("mousemove", handleMouseMove);
  document.addEventListener("mouseup", handleMouseUp);
};

export default handleTextEditorMouseDown;
