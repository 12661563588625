import React, { useState } from "react";
import { CanadaStates, USAStates } from "../../../services/utility";
import { DesignerProducts } from "../../../services/constants";
import { useNavigate } from "react-router-dom";
import { useBwhsApi } from "../../../contexts/bwhsApiProvider";
import { IOrderDetails } from "../../../models";
import { IAppState, ICheckoutState } from "../../../types/states";
import { IErrorInfo } from "../../../types/errorInfo";

export type UserInfoFormProps = {
  state: IAppState;
  checkoutState: ICheckoutState;
  setCheckoutState: React.Dispatch<React.SetStateAction<ICheckoutState>>;
  callErrorDialogueBox: (errorMessage: IErrorInfo) => void;
  busy: boolean;
  setBusy: React.Dispatch<React.SetStateAction<boolean>>;
};
const UserInfoForm = ({
  state,
  checkoutState,
  setCheckoutState,
  callErrorDialogueBox,
  busy,
  setBusy,
}: UserInfoFormProps) => {
  const navigate = useNavigate();
  const {
    userInfo,
    billingAddress,
    userID,
    orderID,
    finalPdfID,
    uploadedFileID,
    pdfDataID,
  } = checkoutState;

  const bwhsApi = useBwhsApi();
  const [invalidEmail, setInvalidEmail] = useState("");
  const [invalidPhone, setInvalidPhone] = useState("");
  const [invalidZipcode, setInvalidZipcode] = useState("");

  const handlePersonalInfoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (value.length > 256 || (name === "phone" && value.length > 15)) {
      /** Maximum limit of 15 digits for phone and 256 characters for name and email*/
      return;
    }

    setCheckoutState((prevState) => ({
      ...prevState,

      userInfo: {
        ...prevState.userInfo,
        [name]: value,
      },
    }));
  };

  const handleBillingInfoChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const { name, value } = e.target;
    if (value.length > 5000) {
      /** Maximum limit of 5000 characters address*/
      return;
    }
    setCheckoutState((prevState) => ({
      ...prevState,

      billingAddress: {
        ...prevState.billingAddress,
        [name]: value,
      },
    }));
  };

  const handleUserInfoSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (state.PDFURL === undefined) {
      sessionStorage.removeItem("application-states");
      sessionStorage.removeItem("checkout-states");
      sessionStorage.removeItem("show-shipping-rates");
      navigate("/");
      return;
    }
    try {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const phoneRegex = /^[0-9]{10}$/;
      if (!emailRegex.test(userInfo.email)) {
        setInvalidEmail("Please enter a valid Email");
        return;
      }
      if (!phoneRegex.test(userInfo.phone)) {
        setInvalidPhone("Please enter a valid Phone number");
        return;
      }

      if (billingAddress.country === "CA") {
        /** Check proper zip code format for Canada - format E.g. A1A 1A1 */
        const canadaZipcodeRegex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
        if (!canadaZipcodeRegex.test(billingAddress.zipcode)) {
          setInvalidZipcode("Please enter a valid Zip/Postal Code");
          return;
        }
        setCheckoutState((prevState) => ({
          ...prevState,

          billingAddress: {
            ...prevState.billingAddress,
            zipcode: billingAddress.zipcode.toUpperCase(),
          },
        }));
      } else {
        /** Check proper zip code format for United States - format E.g. 12345 */
        const usaZipcodeRegex = /^\d{5}$/;
        if (!usaZipcodeRegex.test(billingAddress.zipcode)) {
          setInvalidZipcode("Please enter a valid Zip/Postal Code");
          return;
        }
      }

      setBusy(true);

      // Create user, if needed
      let sessionUserId = userID;
      if (!sessionUserId) {
        const userData: any = await bwhsApi.createUser(
          userInfo,
          billingAddress,
        );
        const newUserId = userData.id;
        setCheckoutState((prevState) => ({
          ...prevState,
          userID: newUserId,
          customerID: userData.stripeCustomerId,
        }));

        sessionUserId = newUserId;
      }

      // Create / update order
      let sessionOrderId = orderID;
      if (!sessionOrderId) {
        let orderData: IOrderDetails = {
          userID: sessionUserId,
          payment: "Pending",
          status: "New",
          billingAddress: billingAddress,
          promotion: state.selectedPromotionalUseOption,
          orderCreationDate: new Date().toDateString(),
          productCode: state.productSelection,
          draft: true,
        };
        if (state.productSelection === DesignerProducts.DIGITAL_HEADSHOT) {
          orderData = {
            ...orderData,
            productType: "Digital",
            shippingDate: new Date().toDateString(),
          };
        } else {
          if (state.productSelection === DesignerProducts.RESUME_8X10) {
            if (state.colorPrintOption) {
              orderData.productSubCode = "color";
            } else {
              orderData.productSubCode = "bw";
            }
          }
          orderData.paperType = state.selectedPaperTypeOption;
          orderData.productType = "Physical";
        }
        const newOrderId = await bwhsApi.createOrder(orderData);
        sessionOrderId = newOrderId;
        setCheckoutState((prevState) => ({
          ...prevState,
          orderID: newOrderId,
        }));
      }

      // upload final PDF
      let finalPdfObjectID = finalPdfID;
      if (!finalPdfObjectID && state.finalPDFBlob) {
        finalPdfObjectID = await bwhsApi.saveFinalPdf(
          state.finalPDFBlob,
          sessionOrderId,
        );
      }

      // upload uploaded file
      let uploadedFileObjectID = uploadedFileID;
      if (!uploadedFileObjectID && state.imageBase64File) {
        uploadedFileObjectID = await bwhsApi.saveOriginalFile(
          state.imageBase64File,
          sessionOrderId,
        );
      }

      // upload user selections for PDF
      let pdfDataObjectID = pdfDataID;
      if (!pdfDataObjectID) {
        const newPDFData = Object.assign({}, state);
        delete newPDFData.imageBase64File;
        delete newPDFData.imageForCanvas;
        delete newPDFData.PDFURL;
        delete newPDFData.finalPDFBlob;
        const options = {
          pdfData: newPDFData,
          pdfID: finalPdfObjectID,
          uploadedFileID: uploadedFileObjectID,
          userID: userID,
        };
        pdfDataObjectID = await bwhsApi.saveHeadshotOptions(options);
      }

      // Update Order
      const updatedOrderData: IOrderDetails = {
        pdfID: finalPdfObjectID,
        pdfDataID: pdfDataObjectID,
        uploadedFileID: uploadedFileObjectID,
        userID: sessionUserId,
      };
      await bwhsApi.updateOrder(updatedOrderData, sessionOrderId);
      setCheckoutState((prevState) => ({
        ...prevState,
        finalPdfID: finalPdfObjectID,
        uploadedFileID: uploadedFileObjectID,
        pdfDataID: pdfDataObjectID,
      }));

      setBusy(false);
      setCheckoutState((prevState) => ({
        ...prevState,
        currentIndex: 2,
      }));
    } catch (error: any) {
      setBusy(false);
      console.error(error);
      callErrorDialogueBox({
        title: error.message + " !",
        message:
          "There's some interruption while connecting. Please try again after some time.",
        cancelBox: false,
        trueMessage: "Okay",
      });
    }
  };

  return (
    <>
      <div className="w-full p-6">
        <form
          onSubmit={handleUserInfoSubmit}
          className="space-y-12 mt-4 w-1/2 mx-auto"
        >
          <div className="w-full bg-white px-10 pb-6 pt-3 rounded-lg shadow-lg">
            <h2 className="pb-2 font-semibold text-lg">Personal Information</h2>
            <div className="space-y-4">
              <div className="flex space-x-5 justify-between">
                <div className="w-full">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Name<label className="text-red-600">*</label>
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    maxLength={256}
                    autoComplete="name"
                    required
                    value={userInfo.name}
                    onChange={handlePersonalInfoChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>
              </div>
              <div className="flex justify-between space-x-5">
                <div className="w-full">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email<label className="text-red-600">*</label>
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    maxLength={256}
                    autoComplete="email"
                    required
                    value={userInfo.email}
                    onChange={(e) => {
                      handlePersonalInfoChange(e);
                      setInvalidEmail("");
                    }}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                  <div className="text-red-600 mt-1 text-sm">
                    {invalidEmail}
                  </div>
                </div>
                <div className="w-full">
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Phone<label className="text-red-600">*</label>
                  </label>
                  <input
                    type="number"
                    id="phone"
                    name="phone"
                    autoComplete="tel-national"
                    required
                    value={userInfo.phone}
                    onChange={(e) => {
                      handlePersonalInfoChange(e);
                      setInvalidPhone("");
                    }}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                  <div className="text-red-600 mt-1 text-sm">
                    {invalidPhone}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full bg-white px-10 pb-6 pt-3 rounded-lg shadow-lg">
            <h2 className="pb-2 font-semibold text-lg">Billing Address</h2>
            <div className="space-y-4">
              <div>
                <label
                  htmlFor="address-1"
                  className="block text-sm font-medium text-gray-700"
                >
                  Address Line 1<label className="text-red-600">*</label>
                </label>
                <input
                  type="text"
                  id="address-1"
                  name="street1"
                  maxLength={5000}
                  autoComplete="billing street-address address-line1"
                  required
                  value={billingAddress.street1}
                  onChange={handleBillingInfoChange}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
              <div>
                <label
                  htmlFor="address-2"
                  className="block text-sm font-medium text-gray-700"
                >
                  Address Line 2
                </label>
                <input
                  type="text"
                  id="address-2"
                  name="street2"
                  maxLength={5000}
                  autoComplete="billing street-address address-line2"
                  value={billingAddress.street2}
                  onChange={handleBillingInfoChange}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
              <div className="flex space-x-5 justify-between">
                <div className="w-full">
                  <label
                    htmlFor="address-city"
                    className="block text-sm font-medium text-gray-700"
                  >
                    City<label className="text-red-600">*</label>
                  </label>
                  <input
                    type="text"
                    id="address-city"
                    name="city"
                    maxLength={5000}
                    autoComplete="billing address-level2"
                    required
                    value={billingAddress.city}
                    onChange={handleBillingInfoChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>
                <div className="w-full">
                  <label
                    htmlFor="address-country"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Country<label className="text-red-600">*</label>
                  </label>
                  <select
                    id="address-country"
                    name="country"
                    autoComplete="billing country-name"
                    required
                    value={billingAddress.country}
                    onChange={(e) => {
                      handleBillingInfoChange(e);
                      setInvalidZipcode("");
                    }}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  >
                    <option value="US">United States</option>
                    <option value="CA">Canada</option>
                  </select>
                </div>
              </div>
              <div className="flex space-x-5 justify-between">
                <div className="w-full">
                  <label
                    htmlFor="address-state"
                    className="block text-sm font-medium text-gray-700"
                  >
                    State/Province<label className="text-red-600">*</label>
                  </label>
                  <select
                    id="address-state"
                    name="state"
                    autoComplete="billing address-level1"
                    required
                    value={billingAddress.state}
                    onChange={handleBillingInfoChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  >
                    {billingAddress.country === "US" ? USAStates : CanadaStates}
                  </select>
                </div>
                <div className="w-full">
                  <label
                    htmlFor="address-zip"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Zip/Postal Code<label className="text-red-600">*</label>
                  </label>
                  <input
                    type="text"
                    id="address-zip"
                    name="zipcode"
                    autoComplete="billing postal-code"
                    required
                    value={billingAddress.zipcode}
                    onChange={(e) => {
                      handleBillingInfoChange(e);
                      setInvalidZipcode("");
                    }}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                  <div className="text-red-600 mt-1 text-sm">
                    {invalidZipcode}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center">
            <button
              type="submit"
              className="w-1/3 px-4 py-2 bg-blue-600 text-white font-medium text-sm leading-5 rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Next
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default UserInfoForm;
