const checkCursorOnImage = (
  mousePositionX: number,
  mousePositionY: number,
  imageHeight: number,
  imageWidth: number,
  imagePositionX: number,
  imagePositionY: number,
) => {
  const top = imagePositionY;
  const left = imagePositionX;
  const right = imagePositionX + imageWidth;
  const bottom = imagePositionY + imageHeight;
  if (
    mousePositionX > left &&
    mousePositionX < right &&
    mousePositionY > top &&
    mousePositionY < bottom
  ) {
    return { x: mousePositionX, y: mousePositionY };
  } else {
    return { x: -1, y: -1 };
  }
};

export default checkCursorOnImage;
