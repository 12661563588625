import { useEffect, useRef } from "react";
import getMouseCoordinates from "../../util/getMouseCoordinates";
import CanvasSection from "./designerComponents/canvasSection";
import DesignerSideBar from "./designerComponents/sideBar";
import checkCursorOnImage from "../../util/checkCursorOnImage";
import { DesignConstants, DesignerProducts } from "../../services/constants";
import { useCallback } from "react";
import { IAppState } from "../../types/states";
import { ProductOptions } from "../../types/designer";

export type DesignerProps = {
  redirect: ProductOptions;
  state: IAppState;
  setState: React.Dispatch<React.SetStateAction<IAppState>>;
  initialState: IAppState;
};

const Designer: React.FC<DesignerProps> = ({
  redirect,
  state,
  setState,
  initialState,
}: DesignerProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const quillRef = useRef(null);

  const {
    imageBase64File,
    imageForCanvas,
    isPortrait,
    selectedBorderOption,
    selectedBorderTypeOption,
    selectedOutline,
    selectedOutlineWidth,
    colorPrintOption,
    scale,
    imagePositionForCanvas,
    imagePositionForPDF,
    imageScalingForPDF,
    textEditorValue,
    textEditorPosition,
    textEditorWidthPx,
    imageCanvasDimensions,
    imageEffectiveDPI,
    scalingPercentage,
  } = state;

  const handleScrollZoom = useCallback(
    (event: WheelEvent | null, zoom: boolean) => {
      if (imageForCanvas) {
        const { canvas, mouseX, mouseY } = getMouseCoordinates(
          event,
          canvasRef,
        );
        if (
          !event ||
          (canvas &&
            mouseX >= 0 &&
            mouseY >= 0 &&
            mouseX <= canvas.width &&
            mouseY <= canvas.height)
        ) {
          let scaling = scalingPercentage;
          let newScale: number;
          let newDPIScale: number;
          if (event) {
            event.preventDefault();
            const deltaX = event.deltaX;
            const deltaY = event.deltaY;
            if (event.shiftKey) {
              newScale = deltaX > 0 ? 0.99 : 1.01; // Scroll smooth zooming
              newDPIScale = deltaX > 0 ? 1.01 : 0.99;
            } else {
              newScale = deltaY > 0 ? 0.96 : 1.04; // Scroll default zooming
              newDPIScale = deltaY > 0 ? 1.04 : 0.96;
            }
          } else {
            newScale = zoom ? 1.008 : 0.992; // Button click zooming
            newDPIScale = zoom ? 0.992 : 1.008;
          }

          let imageWidth = imageCanvasDimensions.width;
          let imageHeight = imageCanvasDimensions.height;
          imageWidth *= newScale;
          imageHeight *= newScale;
          const xDiff = imageWidth - imageCanvasDimensions.width;
          const yDiff = imageHeight - imageCanvasDimensions.height;
          const newDPI = imageEffectiveDPI * newDPIScale;
          if (
            imageWidth < 300 ||
            imageHeight < 300 ||
            (newDPI < 50 && newScale > 1)
          ) {
            return;
          }
          scaling = scaling * newScale;
          let x = imagePositionForCanvas.x;
          let y = imagePositionForCanvas.y;
          const mouseNewValue = event
            ? checkCursorOnImage(
                mouseX,
                mouseY,
                imageCanvasDimensions.height,
                imageCanvasDimensions.width,
                x,
                y,
              )
            : { x: -1, y: -1 };
          const imageCenterX = x + imageCanvasDimensions.width / 2;
          const imageCenterY = y + imageCanvasDimensions.height / 2;
          if (
            mouseNewValue.x === -1 ||
            imageCenterX <= 1 ||
            imageCenterY <= 1 ||
            imageCenterX >= (isPortrait ? 593 : 737) ||
            imageCenterY >= (isPortrait ? 737 : 593)
          ) {
            const xDifference = (imageWidth - imageCanvasDimensions.width) / 2;
            const yDifference =
              (imageHeight - imageCanvasDimensions.height) / 2;
            x -= xDifference;
            y -= yDifference;
          } else {
            const offsetX = mouseNewValue.x - x;
            const offsetY = mouseNewValue.y - y;
            const widthOffsetRatio = offsetX / imageWidth;
            const heightOffsetRatio = offsetY / imageHeight;
            x -= xDiff * widthOffsetRatio;
            y -= yDiff * heightOffsetRatio;
          }

          setState((prevState) => ({
            ...prevState,
            scale: newScale,

            imageCanvasDimensions: {
              width: imageWidth,
              height: imageHeight,
            },

            imagePositionForCanvas: {
              x: x,
              y: y,
            },

            imageEffectiveDPI: newDPI,
            scalingPercentage: scaling,
          }));
        }
      }
    },
    [
      imageCanvasDimensions,
      imageEffectiveDPI,
      imagePositionForCanvas,
      imageForCanvas,
      isPortrait,
      scalingPercentage,
      setState,
    ],
  );

  // Re-render the canvas on value change
  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas == null) {
      return;
    }
    const ctx = canvas.getContext("2d");
    const canvasWidth = canvas.width;
    const canvasHeight = canvas.height;
    if (imageForCanvas) {
      const img = new Image();
      img.onload = () => {
        ctx!.clearRect(0, 0, canvasWidth, canvasHeight);
        const x = imagePositionForCanvas.x;
        const y = imagePositionForCanvas.y;
        const imageWidth = imageCanvasDimensions.width;
        const imageHeight = imageCanvasDimensions.height;
        ctx!.drawImage(img, x, y, imageWidth, imageHeight);

        setState((prevState) => ({
          ...prevState,

          imageScalingForPDF: {
            width: canvas.width / imageWidth,
            height: canvas.height / imageHeight,
          },

          imagePositionForPDF: { x: x, y: y },
        }));

        // This is to for 1 x scale with the PDF dimensions
        const renderScale = DesignConstants.PDF_DPI;

        ctx!.save();
        ctx!.scale(renderScale, renderScale);

        let canvasWidthInches: number = isPortrait
          ? DesignConstants.PORTRAIT_WIDTH_INCHES
          : DesignConstants.LANDSCAPE_WIDTH_INCHES;
        let canvasHeightInches: number = isPortrait
          ? DesignConstants.PORTRAIT_HEIGHT_INCHES
          : DesignConstants.LANDSCAPE_HEIGHT_INCHES;
        canvasWidthInches =
          canvasWidthInches + DesignConstants.BLEED_AREA_WIDTH_INCHES * 2;
        canvasHeightInches =
          canvasHeightInches + DesignConstants.BLEED_AREA_WIDTH_INCHES * 2;

        if (selectedBorderOption !== "noBorder") {
          let sideBorderWidthInches = 0;
          let topBorderWidthInches = 0;
          let bottomBorderWidthInches = 0;

          if (isPortrait) {
            if (selectedBorderTypeOption === "wide") {
              sideBorderWidthInches =
                DesignConstants.PORTRAIT_SIDE_BORDER_WIDE_INCHES;
              topBorderWidthInches =
                DesignConstants.PORTRAIT_TOP_BORDER_WIDE_INCHES;
              bottomBorderWidthInches =
                DesignConstants.PORTRAIT_BOTTOM_BORDER_WIDE_INCHES;
            } else if (selectedBorderTypeOption === "standard") {
              sideBorderWidthInches =
                DesignConstants.PORTRAIT_SIDE_BORDER_STANDARD_INCHES;
              topBorderWidthInches =
                DesignConstants.PORTRAIT_TOP_BORDER_STANDARD_INCHES;
              bottomBorderWidthInches =
                DesignConstants.PORTRAIT_BOTTOM_BORDER_STANDARD_INCHES;
            }
          } else {
            if (selectedBorderTypeOption === "wide") {
              sideBorderWidthInches =
                DesignConstants.LANDSCAPE_SIDE_BORDER_WIDE_INCHES;
              topBorderWidthInches =
                DesignConstants.LANDSCAPE_TOP_BORDER_WIDE_INCHES;
              bottomBorderWidthInches =
                DesignConstants.LANDSCAPE_BOTTOM_BORDER_WIDE_INCHES;
            } else if (selectedBorderTypeOption === "standard") {
              sideBorderWidthInches =
                DesignConstants.LANDSCAPE_SIDE_BORDER_STANDARD_INCHES;
              topBorderWidthInches =
                DesignConstants.LANDSCAPE_TOP_BORDER_STANDARD_INCHES;
              bottomBorderWidthInches =
                DesignConstants.LANDSCAPE_BOTTOM_BORDER_STANDARD_INCHES;
            }
          }

          // Add bleed width
          sideBorderWidthInches =
            sideBorderWidthInches + DesignConstants.BLEED_AREA_WIDTH_INCHES;
          topBorderWidthInches =
            topBorderWidthInches + DesignConstants.BLEED_AREA_WIDTH_INCHES;
          bottomBorderWidthInches =
            bottomBorderWidthInches + DesignConstants.BLEED_AREA_WIDTH_INCHES;

          ctx!.strokeStyle =
            selectedBorderOption === "blackBorder" ? "black" : "white";

          ctx!.lineWidth = topBorderWidthInches;
          ctx!.strokeRect(0, topBorderWidthInches / 2, canvasWidthInches, 0);

          ctx!.lineWidth = sideBorderWidthInches;
          ctx!.strokeRect(sideBorderWidthInches / 2, 0, 0, canvasHeightInches);

          ctx!.lineWidth = sideBorderWidthInches;
          ctx!.strokeRect(
            canvasWidthInches - sideBorderWidthInches / 2,
            0,
            0,
            canvasHeightInches,
          );

          ctx!.lineWidth = bottomBorderWidthInches;
          ctx!.strokeRect(
            0,
            canvasHeightInches - bottomBorderWidthInches / 2,
            canvasWidthInches,
            0,
          );

          if (selectedOutline === "outlineYes") {
            const outlineWidthInches = selectedOutlineWidth / renderScale;
            ctx!.strokeStyle =
              selectedBorderOption === "blackBorder" ? "white" : "black";
            ctx!.lineWidth = outlineWidthInches;

            ctx!.strokeRect(
              sideBorderWidthInches - outlineWidthInches,
              topBorderWidthInches - outlineWidthInches / 2,
              canvasWidthInches -
                (sideBorderWidthInches + sideBorderWidthInches) +
                outlineWidthInches * 2,
              0,
            );

            ctx!.strokeRect(
              sideBorderWidthInches - outlineWidthInches / 2,
              topBorderWidthInches - outlineWidthInches,
              0,
              canvasHeightInches -
                (topBorderWidthInches + bottomBorderWidthInches) +
                outlineWidthInches * 2,
            );

            ctx!.strokeRect(
              canvasWidthInches -
                sideBorderWidthInches +
                outlineWidthInches / 2,
              topBorderWidthInches - outlineWidthInches,
              0,
              canvasHeightInches -
                (topBorderWidthInches + bottomBorderWidthInches) +
                outlineWidthInches * 2,
            );

            ctx!.strokeRect(
              sideBorderWidthInches - outlineWidthInches,
              canvasHeightInches -
                bottomBorderWidthInches +
                outlineWidthInches / 2,
              canvasWidthInches -
                (sideBorderWidthInches + sideBorderWidthInches) +
                outlineWidthInches * 2,
              0,
            );
          }

          // Set text editor width
          setState((prevState) => ({
            ...prevState,

            textEditorWidthPx:
              (canvasWidthInches - sideBorderWidthInches * 2) *
              DesignConstants.PDF_DPI,

            textEditorPosition: {
              ...prevState.textEditorPosition,
              x: sideBorderWidthInches * DesignConstants.PDF_DPI,
            },
          }));
        } else {
          if (isPortrait) {
            // Set text editor width
            setState((prevState) => ({
              ...prevState,

              textEditorWidthPx:
                (canvasWidthInches -
                  DesignConstants.PORTRAIT_SIDE_BORDER_STANDARD_INCHES * 2) *
                DesignConstants.PDF_DPI,

              textEditorPosition: {
                ...prevState.textEditorPosition,
                x:
                  DesignConstants.PORTRAIT_SIDE_BORDER_STANDARD_INCHES *
                  DesignConstants.PDF_DPI,
              },
            }));
          } else {
            // Set text editor width
            setState((prevState) => ({
              ...prevState,

              textEditorWidthPx:
                (canvasWidthInches -
                  DesignConstants.LANDSCAPE_SIDE_BORDER_STANDARD_INCHES * 2) *
                DesignConstants.PDF_DPI,

              textEditorPosition: {
                ...prevState.textEditorPosition,
                x:
                  DesignConstants.LANDSCAPE_BOTTOM_BORDER_STANDARD_INCHES *
                  DesignConstants.PDF_DPI,
              },
            }));
          }
        }

        // Bleed box
        const bleedLineWidth = 2 / renderScale;
        ctx!.setLineDash([
          DesignConstants.BLEED_AREA_WIDTH_INCHES,
          DesignConstants.BLEED_AREA_WIDTH_INCHES,
        ]);
        ctx!.strokeStyle = "white";
        ctx!.lineWidth = bleedLineWidth;
        ctx!.strokeRect(
          DesignConstants.BLEED_AREA_WIDTH_INCHES,
          DesignConstants.BLEED_AREA_WIDTH_INCHES,
          canvasWidthInches - DesignConstants.BLEED_AREA_WIDTH_INCHES * 2,
          canvasHeightInches - DesignConstants.BLEED_AREA_WIDTH_INCHES * 2,
        );
        ctx!.setLineDash([
          0,
          DesignConstants.BLEED_AREA_WIDTH_INCHES,
          DesignConstants.BLEED_AREA_WIDTH_INCHES,
          0,
        ]);
        ctx!.strokeStyle = "black";
        ctx!.lineWidth = bleedLineWidth;
        ctx!.strokeRect(
          DesignConstants.BLEED_AREA_WIDTH_INCHES,
          DesignConstants.BLEED_AREA_WIDTH_INCHES,
          canvasWidthInches - DesignConstants.BLEED_AREA_WIDTH_INCHES * 2,
          canvasHeightInches - DesignConstants.BLEED_AREA_WIDTH_INCHES * 2,
        );
        ctx!.setLineDash([]);

        ctx!.restore();
      };
      img.src = imageForCanvas;
    } else {
      ctx!.clearRect(0, 0, canvasWidth, canvasHeight);
    }
  }, [
    setState,
    imageBase64File,
    imageForCanvas,
    isPortrait,
    scale,
    imagePositionForCanvas,
    selectedBorderOption,
    selectedBorderTypeOption,
    selectedOutline,
    selectedOutlineWidth,
    imageCanvasDimensions,
  ]);

  useEffect(() => {
    const canvasElement = canvasRef.current;
    if (canvasElement == null) {
      return;
    }
    const handleMouseDown = (event: MouseEvent) => {
      event.preventDefault();
      if (imageForCanvas) {
        const { canvas, mouseX, mouseY, rect } = getMouseCoordinates(
          event,
          canvasRef,
        );
        if (
          canvas &&
          mouseX >= 0 &&
          mouseY >= 0 &&
          mouseX <= canvas.width &&
          mouseY <= canvas.height
        ) {
          canvasElement.style.cursor = "grabbing";
          const startDragPos = { x: mouseX, y: mouseY };

          const handleMouseMove = (moveEvent: MouseEvent) => {
            moveEvent.preventDefault();
            const currentX = moveEvent.clientX - rect.left;
            const currentY = moveEvent.clientY - rect.top;
            const dx = currentX - startDragPos.x;
            const dy = currentY - startDragPos.y;
            const canvas = canvasRef.current;
            if (canvas == null) {
              return;
            }
            setState((prevState) => {
              const x = prevState.imagePositionForCanvas.x + dx;
              const y = prevState.imagePositionForCanvas.y + dy;
              if (
                x > -(imageCanvasDimensions.width * 0.5) &&
                y > -(imageCanvasDimensions.height * 0.5) &&
                x < canvas.width - imageCanvasDimensions.width * 0.5 &&
                y < canvas.height - imageCanvasDimensions.height * 0.5
              ) {
                return {
                  ...prevState,
                  imagePositionForCanvas: {
                    x: prevState.imagePositionForCanvas.x + dx,
                    y: prevState.imagePositionForCanvas.y + dy,
                  },
                };
              } else return prevState;
            });
            startDragPos.x = currentX;
            startDragPos.y = currentY;
          };

          const handleMouseUp = () => {
            canvasElement.style.cursor = "grab";
            document.removeEventListener("mousemove", handleMouseMove);
            document.removeEventListener("mouseup", handleMouseUp);
          };

          document.addEventListener("mousemove", handleMouseMove);
          document.addEventListener("mouseup", handleMouseUp);
        }
      }
    };

    function handleScroll(event: WheelEvent) {
      handleScrollZoom(event, true);
    }

    canvasElement.addEventListener("wheel", handleScroll);
    canvasElement.addEventListener("mousedown", handleMouseDown);
    return () => {
      canvasElement.removeEventListener("wheel", handleScroll);
      canvasElement.removeEventListener("mousedown", handleMouseDown);
    };
  }, [
    setState,
    handleScrollZoom,
    scale,
    imageForCanvas,
    imagePositionForCanvas,
    imageCanvasDimensions,
    imageEffectiveDPI,
    isPortrait,
  ]);

  useEffect(() => {
    if (
      !(
        redirect === DesignerProducts.HEADSHOT_PRINTS ||
        redirect === DesignerProducts.DIGITAL_HEADSHOT
      )
    ) {
      setState((prevState) => ({
        ...prevState,
        selectedBorderOption: "noBorder",
      }));
    }
    setState((prevState) => ({
      ...prevState,
      productSelection: redirect,
    }));
  }, [redirect, setState]);

  return (
    <>
      <div className="flex h-full min-h-screen">
        {/* Left side section */}
        <DesignerSideBar
          imageBase64File={imageBase64File}
          setState={setState}
          isPortrait={isPortrait}
          selectedBorderOption={selectedBorderOption}
          selectedBorderTypeOption={selectedBorderTypeOption}
          selectedOutline={selectedOutline}
          selectedOutlineWidth={selectedOutlineWidth}
          colorPrintOption={colorPrintOption}
          imageScalingForPDF={imageScalingForPDF}
          imagePositionForPDF={imagePositionForPDF}
          textEditorValue={textEditorValue}
          redirect={redirect}
          canvasRef={canvasRef}
          imageEffectiveDPI={imageEffectiveDPI}
          imageCanvasDimensions={imageCanvasDimensions}
          initialState={initialState}
          isSelectedFilePdf={state.isSelectedFilePdf}
          productSelection={state.productSelection}
        />

        {/* Right side section */}
        <CanvasSection
          canvasRef={canvasRef}
          isPortrait={isPortrait}
          imageBase64File={imageBase64File}
          textEditorValue={textEditorValue}
          textEditorPosition={textEditorPosition}
          textEditorWidthPx={textEditorWidthPx}
          setState={setState}
          redirect={redirect}
          quillRef={quillRef}
          imageEffectiveDPI={imageEffectiveDPI}
          imagePositionForCanvas={imagePositionForCanvas}
          imageCanvasDimensions={imageCanvasDimensions}
          selectedBorderOption={selectedBorderOption}
          selectedBorderTypeOption={selectedBorderTypeOption}
          handleScrollZoom={handleScrollZoom}
          scalingPercentage={scalingPercentage}
          productSelection={state.productSelection}
          isSelectedFilePdf={state.isSelectedFilePdf}
        />
      </div>
    </>
  );
};

export default Designer;
