import React from "react";

export type RadioButtonProps = {
  id: string;
  name: string;
  value: string;
  disabled?: boolean;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
};

const RadioButton = ({
  id,
  name,
  value,
  disabled = false,
  checked,
  onChange,
  label,
}: RadioButtonProps) => {
  return (
    <>
      <div>
        <input
          type="radio"
          name={name}
          id={id}
          value={value}
          disabled={disabled}
          checked={checked}
          onChange={onChange}
          className="peer hidden"
        />
        <label
          htmlFor={id}
          className="peer-checked:[&_p:after]:block peer-disabled:[&_p:before]:border-black-200/[.3] peer-disabled:[&_p:after]:bg-black-200/[.4] peer-disabled:[&_p]:text-black-200/[.75]"
        >
          <p className="relative text-xs text-black-200 font-normal flex items-start gap-1 before:relative before:top-0.5 before:shrink-0 before:block before:w-3 before:h-3 before:border before:border-black-300 before:rounded-[100%] after:absolute after:left-[2px] after:top-1 after:w-2 after:h-2 after:bg-black-300 after:rounded-[100%] after:hidden">
            <span>{label}</span>
          </p>
        </label>
      </div>
    </>
  );
};

export default RadioButton;
